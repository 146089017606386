export const get = (url,headers={}) => {

    return new Promise((resolve, reject) => {
        fetch(url,{
            headers
        })
            .then(res => res.json())
            .then(result => {
                if(result.success){

                    resolve(result.result)
                }
                if(result.error){
                    reject(result?.error?.message)
                }

            })

    })
}

export const post = (url,payload,headers={}) => {

    return new Promise((resolve, reject) => {
        fetch(url,{
            headers,
            "method":"POST",
            body: JSON.stringify({
                payload
            })
        })
            .then(res => res.json())
            .then(result => {
                console.log(result)

                if(result.success){

                    resolve(result.result)
                }
                if(!result.success){
                    reject(result.error.message)
                }

            })

    })
}


export const put = (url,payload,headers={}) => {

    return new Promise((resolve, reject) => {
        fetch(url,{
            headers,
            "method":"PUT",
            body: JSON.stringify({
                payload
            })
        })
            .then(res => res.json())
            .then(result => {

                if(result.success){

                    resolve(result.result)
                }
                if(!result.success){
                    reject(result.error.message)
                }

            })

    })
}


export const Delete = (url,payload,headers={}) => {

    return new Promise((resolve, reject) => {
        fetch(url,{
            headers,
            "method":"DELETE",
            body: JSON.stringify({
                payload
            })
        })
            .then(res => res.json())
            .then(result => {

                if(result.success){

                    resolve(result.result)
                }
                if(!result.success){
                    reject(result.error.message)
                }

            })

    })
}


export const uploadFilesArray = (url,filesArray,headers={}) => {
    console.log(headers)
    const formData = new FormData()
    for (let i = 0; i < filesArray.length; i += 1) {
        formData.append(filesArray[i].name, filesArray[i].file)
    }

    return new Promise((resolve, reject) => {
        fetch(url,{
            headers,
            method: 'POST',
            body:formData,

        })
            .then(res => res.json())
            .then(result => {
                console.log(result)

                if(result.success){

                    resolve(result.result)
                }
                if(!result.success){
                    reject(result.error.message)
                }

            })
    })

}
