import React,{useEffect} from 'react';
import {connect} from "react-redux";
import {addToBag} from "../../api";
import {toast} from "react-toastify";

/**
 * @author Pranav Singhal <pranavsinghal96@gmail.com>
 * @author [Pranav Singhal](https://github.com/pranav-singhal)
 * @createdOn   19-03-2020, 18:55
 */


function AddToBag({productId,bag,dispatch,user,products}) {
    const handleClick = () => {

        if(!bag.addedIds.includes(productId)){
            addToBag([{productId,quantity:1}])
                .then(message => {
                    console.log(message)
                })

            dispatch({
                type: 'bag/ADD_TO_BAG',
                payload:{productId}
            })
        }
    }

    const getButtonText = () => {
        if(
            products.productsByIds[productId].status !=="AVAILABLE"
        ){
            return "Sold Out"
        }
        if(bag.addedIds.includes(productId)){
            return "Added to Bag"
        }
        if(products.productsByIds[productId].status ==="AVAILABLE" && !bag.addedIds.includes(productId)){
            return "Add to Bag"
        }

    }
    return (

        <button className="btn"
                // onClick={() => addToBag(props.match.params.storeId,1,item)}
            disabled={ products.productsByIds[productId].status !=="AVAILABLE" || bag.addedIds.includes(productId) }
            onClick={handleClick}
        >
            {getButtonText()}

        </button>

    );
}


const mapStateToProps = ({products,bag,dispatch,user}) => ({bag,dispatch,user,products})
export default connect(mapStateToProps)(AddToBag);
