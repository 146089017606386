import { LOG_SHOW } from "../constants";

 const SetValue = (key, value) => {
    localStorage.setItem(key, value);
}

const GetValue = ( key ) =>{
    return localStorage.getItem(key);
}
const GetValueArr = ( key ) =>{
    const retreive= localStorage.getItem(key);
    if(retreive === null || retreive === "")
    {
        return [];
    }
    return JSON.parse(retreive);

}

const PrintLogs = ( key, value ) =>{
    if(LOG_SHOW === true)
    {    
        console.log(key, value)
    }
}


export {
    SetValue,
    GetValue,
    GetValueArr,
    PrintLogs
}