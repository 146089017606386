import React, {  } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { toast } from "react-toastify";
import "./App.scss";
import { AppProvider } from "./ContextProvider/AppProvider";
import "react-toastify/dist/ReactToastify.css";
import DefaultLayout from "./containers/DefaultLayout";
import Loader from "./components/Loader";
import Bag from "./views/bag/Bag";
import {Drawer} from "antd";
import CacheBuster from "./components/CacheBuster";
console.disableYellowBox = true;
const loading = () => (
    <div className="container-fluid pt-5">
        <Loader />
    </div>
);
// const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));\

const Login = React.lazy(() => import("./views/login"));

toast.configure({
  hideProgressBar: true
});

const App = () => {
  return (
      <CacheBuster>
          <div className="shop" style={{ minheight: "100vh", backgroundColor: 'white'}}>
              <AppProvider>
                  <HashRouter>
                      <React.Suspense fallback={loading()}>
                          <Switch>
                              {/*<Route*/}
                              {/*    exact={true}*/}
                              {/*    path="/login"*/}
                              {/*    name="Login"*/}
                              {/*    render={props => <Login {...props} />}*/}
                              {/*/>*/}
                              <Route
                                  path="/"
                                  name="Home"
                                  render={props => <DefaultLayout {...props} />}
                              />
                          </Switch>

                      </React.Suspense>
                  </HashRouter>
              </AppProvider>
          </div>
      </CacheBuster>
  );
};

export default App;
