import React, {useState} from 'react'
import {connect} from "react-redux";
import {addToBag, removeItemFromBag} from "../../api";
import {getProductImageLink} from "../../services/product";
import Loader from "../Loader";

const CartItem = (props) => {
    const {productId, dispatch, item} = props;
    const [loading, setLoading] = useState(false);
    const product = props.products.productsByIds[productId];
    const link = getProductImageLink(product);

    const toggleQuantity = (newQuantity) => {
        setLoading(true);
        dispatch({
            type: 'bag/TOGGLE_QUANTITY',
            payload: {
                productId, newQuantity
            }
        });
        addToBag([{productId, quantity: newQuantity}]).then(res => {

            setLoading(false)
        }).catch(err => {
            setLoading(false)
        })
    };

    const deleteItem = () => {
        setLoading(true);
        removeItemFromBag([productId])
            .then(result => {
                console.log("result", result)
            })
            .catch(err => {
                console.log(err)
            }).finally(() => {
            dispatch({
                type: 'bag/DELETE_FROM_BAG',
                payload: {productId}
            });
            setLoading(false)
        })
    };

    return (
        !product ?
            <Loader/>
            :

            <div className="row">
                <div className="col-4 px-0">
                    <img src={link} alt={product.brand}/>
                </div>
                <div className="col-8 my-5 px-0">
                    <h5 className="font-weight-bold">
                        {product.title}
                        <span className="float-right">
                        ${product.price}
                    </span>
                    </h5>
                    {product.brand}
                    <div>
                        <button
                            className="btn"
                            style={{backgroundColor: "#eee"}}
                            disabled={item.quantity === 1 || loading}
                            onClick={() => {
                                toggleQuantity(item.quantity - 1)
                            }}
                        >
                            <span className="icon-minus"/>
                        </button>
                        <span className="mx-2">
                            {item.quantity}
                        </span>
                        <button
                            style={{backgroundColor: "#eee"}}
                            className="btn"
                            disabled={loading}
                            onClick={() => {
                                toggleQuantity(item.quantity + 1)
                            }}
                        >
                            <span className="icon-plus"/>
                        </button>
                    </div>
                </div>
                <div className="col-12">
                    <div className="row">
                        <div className="col-6">
                            <a className="mt-2 text-underline" onClick={deleteItem}>
                                <i className="fas fa-trash"/>
                            </a>
                        </div>
                        <div className="col-6">
                            <p style={{fontSize: "1.4rem"}} className="text-right">
                                Total: ${product.price * item.quantity}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <hr/>
                </div>
            </div>
    );
};

const mapStateToProps = ({products, dispatch}) => ({products, dispatch});
export default connect(mapStateToProps)(CartItem)
