import { API_ENDPOINT } from "../../config";
import { get, post, put, uploadFilesArray } from "../methods";
const urlPrefix = `${API_ENDPOINT}/`


export const getTenantDetails = () => {
    const headers = new Headers();

    const url = `${urlPrefix}tenant`
    return get(url,headers)
}

export const fetchStoresForTenant = (apiKey) => {
    const headers = new Headers();
    headers.append("api-key", apiKey);
    const url = `${urlPrefix}stores`
    return get(url,headers)

}

export const fetchStoreApiForUser = (storeId,apiKey) => {
    const headers = {
        'api-key':apiKey,
        'Content-Type': 'application/json'
    }
    // headers.append("api-key",apiKey);
    const payload = {storeId}

    const url = `${urlPrefix}setStore`

    return put(url,payload,headers)
}

export const getStoreCatalog = (apiKey) => {
    const headers = {
        'api-key':apiKey,
    }
    const url = `${urlPrefix}store/catalog`
    return get(url,headers)
}

export const getRecommendations = (apiKey) => {
    const headers = {
        'api-key':apiKey,
    }
    const url = `${urlPrefix}store/catalog?isRecommendation=true`
    return get(url,headers)
}

export const getProductDetails = (apiKey,productId) => {
    const headers = {
        'api-key':apiKey,
    }
    const url = `${urlPrefix}getProductDetail/${productId}`
    return get(url,headers)
}
export const fetchDiscounts = (type) => {

    const apiKey = localStorage.getItem('tenantApiKey')
    const headers = {
        'api-key':apiKey,
        'Content-Type': 'application/json'
    }
    const url =`${urlPrefix}discounts?types=${type || ''}`
    return get(url,headers)

}
