import React from 'react';
import {Link} from "react-router-dom";
import AddToBag from "../AddToBag";
import './style.scss'

/**
 * @author Pranav Singhal <pranavsinghal96@gmail.com>
 * @author [Pranav Singhal](https://github.com/pranav-singhal)
 * @createdOn   22-03-2020, 9:55
 */


function ProductCard({product,className}) {
    return (
        <div

            className={className || "col-6 col-md-4"}
        >
            <div className="row text-center" style={{minHeight: "15rem"}}>
                <div className="col-12 ">
                    <Link to={`/product/detail/${product.productId}`}>

                        <img style={{
                            maxWidth: "100%", maxHeight:"20rem"

                        }}
                             className="product-image"
                             src={product.assets[0].link}
                             alt=""/>


                    </Link>
                </div>
                <div className="col-12">
                    <p className="font-weight-bold mb-0">
                        {product.title}
                    </p>
                    <span>
                        {product.brand}
                    </span>


                </div>
                <div className="col-12">
                    <span className="font-weight-bold">
                        ${product.price}
                    </span>
                    <em style={{fontSize:"0.85rem"}}>*Incl. of all taxes</em>

                    <div className="pull-right button-container mt-4">
                        <AddToBag productId={product.productId}/>
                    </div>
                </div>
            </div>
            <div className="thumbnail product-thumbnail ">


                <h5 className="product-title bold">

                </h5>
                <div>
                    <h4 className="product-title">

                    </h4>


                </div>
            </div>

        </div>
    );
}

export default ProductCard;
