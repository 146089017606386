import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

/**
 * @author Pranav Singhal <pranavsinghal96@gmail.com>
 * @author [Pranav Singhal](https://github.com/pranav-singhal)
 * @createdOn  18-2020-05, Mon, 13:4
 */
EmptyCart.propTypes = {

};

function EmptyCart(props) {
    return (
        <div className="text-center col-12 mt-5 pt-5">

            <h3 className="mt-5" style={{ textTransform: "capitalize" }}>Bag is empty</h3>
            <p className="lead">Click below to explore products</p>

            <div className="col-12">
                <Link to="/">
                    <button className="border-theme   small">
                        Continue Shopping
                    </button>
                </Link>
            </div>

        </div>
    );
}

export default EmptyCart;
