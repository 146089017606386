import { API_ENDPOINT } from "../../config";
import {Delete, get, post, put, uploadFilesArray} from "../methods";
const urlPrefix = `${API_ENDPOINT}/user/`
export const setUserAddress = (payload) => {
    const apiKey = localStorage.getItem('storeApiKey')
const authToken = localStorage.getItem('user_session')
const authHeaders = {
    'Authorization': `Bearer ${authToken}`,
    'Content-Type': 'application/json',
    'api-key': apiKey
}
    const url = `${urlPrefix}address/add`
    return post(url, payload, {
        ...authHeaders
    })
}

export const setGovtID = (payload) => {
    const apiKey = localStorage.getItem('storeApiKey')
    const authToken = localStorage.getItem('user_session')
    const authHeaders = {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json',
        'api-key': apiKey
    }
    const url = `${urlPrefix}govtId/add`
    return post(url, payload, {
        ...authHeaders
    })
}

export const placeOrder = (payload) => {
    const apiKey = localStorage.getItem('storeApiKey')
    const authToken = localStorage.getItem('user_session')
    const authHeaders = {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json',
        'api-key': apiKey
    }
    const url = `${urlPrefix}createOrder`
    return post(url, payload, authHeaders)
}


export const updateUserDetails = (payload) => {
    const apiKey = localStorage.getItem('storeApiKey')
    const authToken = localStorage.getItem('user_session')
    console.log(payload)
    const authHeaders = {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json',
        'api-key': apiKey
    }
    const url = `${urlPrefix}details/update`
    return put(url, payload, authHeaders)
}

export const updateUserAddress = (addressId, payload) => {
    const apiKey = localStorage.getItem('storeApiKey')
    const authToken = localStorage.getItem('user_session')
    const authHeaders = {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json',
        'api-key': apiKey
    }
    const url = `${urlPrefix}address/update/${addressId}`
    return put(url, payload, authHeaders)
}

export const updateGovtId = (govtId, payload) => {
    const apiKey = localStorage.getItem('storeApiKey')
    const authToken = localStorage.getItem('user_session')
    const authHeaders = {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json',
        'api-key': apiKey
    }
    if (payload.expiration) {
        payload.expiration = new Date(payload.expiration).toISOString()
    }
    const url = `${urlPrefix}updateGovtId/${govtId}`
    return put(url, payload, authHeaders)
}

export const getAllOrders = () => {
    const apiKey = localStorage.getItem('storeApiKey')
    const authToken = localStorage.getItem('user_session')
    const authHeaders = {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json',
        'api-key': apiKey
    }
    const url = `${urlPrefix}getAllOrders`
    return get(url, authHeaders)
}


export const imageUpload = (file) => {
    const authToken = localStorage.getItem('user_session')
    const apiKey = localStorage.getItem('storeApiKey')
    console.log(file)
    const url = `${urlPrefix}uploadImage`
    console.log(file)
    return uploadFilesArray(url, [{ name: "file", file }], {
        'Authorization': `Bearer ${authToken}` ,
        'api-key': apiKey
    })
}

export const cancelOrder = (orderId) => {
    const authToken = localStorage.getItem('user_session')
    const apiKey = localStorage.getItem('storeApiKey')
    const  url = `${urlPrefix}cancel/${orderId}`
    const payload = {}
    return post(url,payload,{
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json',
        'api-key': apiKey
    })
}

export const clearBag = () => {

    const authToken = localStorage.getItem('user_session')
    const apiKey = localStorage.getItem('storeApiKey')
    const  url = `${urlPrefix}emptyCart`
    const headers = {
        'Authorization': `Bearer ${authToken}` ,
        'api-key': apiKey
    }
    return Delete(url,{},headers)


}
