const actions = {
    SET_STATE: 'bag/SET_STATE',
    GET_STATE: 'bag/GET_STATE',
    ADD_TO_BAG: 'bag/ADD_TO_BAG',
    SYNC_BAG: 'bag/SYNC_BAG',
    FETCH_BAG: 'bag/FETCH_BAG',
    CLEAR_BAG: "bag/CLEAR_BAG",
    DELETE_FROM_BAG: 'bag/DELETE_FROM_BAG',
    TOGGLE_QUANTITY: 'bag/TOGGLE_QUANTITY'
}

export default actions
