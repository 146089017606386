import {API_ENDPOINT} from "../config";
import {get, post} from "./methods";
export const addToBag = (payload) =>{
    const apiKey = localStorage.getItem('storeApiKey')
    const url = `${API_ENDPOINT}/user/addCart`
    const authToken = localStorage.getItem('user_session')
    return new Promise((resolve, reject) => {
        fetch(url,{
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json',
                'api-key':apiKey
            },
            body: JSON.stringify({
                payload
            })
        })
            .then(res => res.json())
            .then(result => {
                console.log(result)
                if(result.success){

                resolve(result.result.message)
                }
                if(result.error){
                    console.error(result.error.message)
                    reject(result?.error?.message)
                }

            })
            .catch(err => {
                console.log(err)
                reject(err)
            })

    })
}

export const getBag = () => {
    const apiKey = localStorage.getItem('storeApiKey')
    const url = `${API_ENDPOINT}/user/getCart`
    const authToken = localStorage.getItem('user_session')
    return new Promise((resolve, reject) => {
        fetch(url,{
            headers:{
                'Authorization': `Bearer ${authToken}`,
                'api-key':apiKey
            }
        })
            .then(res => res.json())
            .then(result => {
                if(result.success){

                    resolve(result.result)
                }
                if(result.error){
                    console.error(result.error.message)
                    reject(result?.error?.message)
                }

            })

    })
}


export const removeItemFromBag = (payload) => {
    const url = `${API_ENDPOINT}/user/removeCartItems`
    const apiKey = localStorage.getItem('storeApiKey')
    const authToken = localStorage.getItem('user_session')
    return new Promise((resolve, reject) => {
        fetch(url,{
            method: 'DELETE',
            headers:{
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
                'api-key':apiKey
            },
            body: JSON.stringify({
                payload
            })
        })
            .then(res => res.json())
            .then(result => {
                console.log(result)
                console.log(result)
                if(result.success){

                    resolve(result.result)
                }
                if(!result.success){
                    reject(result.error.message)
                }

            })
    })
}


export const registerUser = (payload) => {
    const apiKey = localStorage.getItem('storeApiKey')
    const url = `${API_ENDPOINT}/register`
    return new Promise((resolve, reject) => {
        fetch(url,{
            method: 'POST',
            headers:{
                'Content-Type': 'application/json',
                'api-key':apiKey

            },
            body: JSON.stringify({
                payload
            })

        })
            .then(res => res.json())
            .then(result => {
                console.log(result)
                console.log(result)
                if(result.success){

                    resolve(result.result)
                }
                if(!result.success){
                    reject(result.error.message)
                }

            })
    })
}

export const getUserDetails = () => {
    const apiKey = localStorage.getItem('storeApiKey')
    // console.log(apiKey)
    const authToken = localStorage.getItem('user_session')
    const headers = {
        'Authorization': `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "api-key": apiKey
    }
    const url = `${API_ENDPOINT}/user/detail`
    return get(url,headers)

}

export const login = (email,password) => {

    const apiKey = localStorage.getItem('storeApiKey')
    console.log(apiKey)
    const headers = {
        "Content-Type": "application/json",
        "api-key": apiKey
    }
    const url = `${API_ENDPOINT}/login`
    return post(url,{email,password},headers)

}


// /api/v1/resetPassword


export const forgotPassword = (email) => {
    const url = `${API_ENDPOINT}/resetPassword`
    return post(url,{email},{
        "Content-Type":"application/json"
    })
}


export const resetPassword =(passwordToken, newPassword) => {
    const url = `${API_ENDPOINT}/changePassword/${passwordToken}`
    return post(url,{password:newPassword},{
        "Content-Type":"application/json"
    })
}
