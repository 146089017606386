import {all, takeEvery, put, call} from 'redux-saga/effects'
import actions from "./actions";
import {addToBag, getBag} from "../../api";
import {toast} from "react-toastify";
import {clearBag} from "../../api/user";

export function* LOAD_ALL_BAGS() {

    try {
        const response = yield call(getBag);
        const addedItemsByIds = {};
        const addedIds = response && response.map(addedProduct => {
            addedItemsByIds[addedProduct.productId] = {quantity: addedProduct.quantity};
            return addedProduct.productId
        });
        yield put({type: actions.SET_STATE, payload: {addedItemsByIds, addedIds}})
    } catch (e) {
        console.error("user not logged In",e);
        const localBag = JSON.parse(localStorage.getItem('bag'));
        yield put({type: actions.SET_STATE, payload: {...localBag}})
    }

}


export function* ADD_TO_BAG() {

    //TODO - add items to bag in the backend if the user is logged In
}
export function* CLEAR_BAG() {

    localStorage.removeItem("bag")
    try {
        yield call(clearBag)
        yield put({
            type: actions.SET_STATE,
            payload: {addedIds:[], addedItemsByIds:{}}
        })
    }catch (e) {
        console.error(e)

    }


}

export function* SYNC_BAG() {
    const bag = JSON.parse(localStorage.getItem('bag'));


    try {
        if (bag) {
            const bagItems = bag.addedIds.map(addedId => {
                return {productId: addedId, quantity: bag['addedItemsByIds'][addedId].quantity}
            });

            if (bagItems.length !== 0) {
                const response = yield call(addToBag, bagItems);


            }
        }

        yield put({type: actions.FETCH_BAG})


    } catch (e) {
        yield put({
            type: actions.CLEAR_BAG
        })
        console.error("error response",e);
        // toast.error(e.toString())
    }
}


export default function* rootSaga() {
    yield all([
        takeEvery(actions.CLEAR_BAG,CLEAR_BAG),
        takeEvery([actions.ADD_TO_BAG], ADD_TO_BAG),
        takeEvery(actions.SYNC_BAG, SYNC_BAG),
        takeEvery([actions.FETCH_BAG], LOAD_ALL_BAGS),
        LOAD_ALL_BAGS(), // run once on app load to check user auth
    ])
}
