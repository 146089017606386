import React from 'react';

const Home = React.lazy(() => import('./views/Home'));
const Login = React.lazy(() => import('./views/login'));
const StoreCatalog = React.lazy(() => import('./views/catalog/CatalogLanding'))
const Bag = React.lazy(() => import('./views/bag/Bag.js'))
const Order = React.lazy(() => import('./views/order/Order.js'))
const BoxDetail = React.lazy(() => import('./views/box/BoxDetail.js'))
const Address = React.lazy(() => import('./views/checkout/Address.js'))
const Pay = React.lazy(() => import('./views/checkout/Pay.js'))
const Confirmation = React.lazy(() => import('./views/checkout/Confirmation.js'))
const ProductDetail = React.lazy(() => import('./views/catalog/ProductDetail'));
const Profile = React.lazy(()=> import('./views/Profile'))
const ForgotPassword = React.lazy(()=> import('./views/ForgotPassword'))
const ResetPassword = React.lazy(()=>import('./views/ResetPassword'))
const Terms = React.lazy(()=> import('./views/Terms'))
const FAQS  = React.lazy(()=> import('./views/faqs'))
const OrderCancelled = React.lazy(()=>import('./views/OrderCancelled'))
/**
 *
 * @namespace Routes
 *
 * @description Declares all the routes along with all components they render
 */
const route = [
    { path: '/', exact: true, name: 'Home', component: Home },
    { path: '/shop/:storeId', exact: true, name: 'Home', component: Home },
    { path: '/shop', exact: true, name: 'Home', component: Home },
    { path: '/drawer/:tab', exact: false, name: 'Home', component: Home },
    { path: '/forgot-password', exact: true, name: 'forgot', component: ForgotPassword },
    { path: '/reset-password', exact: true, name: 'forgot', component: ResetPassword },

    { path: '/catalog/:storeid', exact: true, name: 'Store Catalog', component: StoreCatalog },
    { path: '/bag', exact: true, name: 'Store Bag', component: Home },
    { path: '/order', exact: true, name: 'Order Bag', component: Order },
    { path: '/box/detail/:productId', exact: true, name: 'Box Detail', component: BoxDetail },
    { path: '/product/detail/:productId', exact: true, name: 'Product Detail', component: ProductDetail },
    { path: '/checkout/address', exact: true, name: 'Address', component: Home },
    { path: '/checkout/pay', exact: true, name: 'Pay', component: Pay },
    { path: '/checkout/pay/paypal', exact: true, name: 'Pay', component: Pay },
    { path: '/checkout/confirm', exact: true, name: 'Order Confirmation', component: Confirmation },
    {path: '/order-cancelled/:orderId',name:'orderCancelled',component: OrderCancelled},

    { path: '/terms', exact: true, name: "Terms", component: Terms },
    { path: '/faqs', exact: true, name: "FAQs", component: FAQS },
    { path: '/:tab', exact: false, name: "User Profile", component: Profile }
//    TODO - change the tab url to have a suffix before the :tab
//             - change "/:tab" to "/user/:tab"


]

export const drawerRoutes = [
    { path: '/drawer/:tab', exact:false, name: 'Home', component: Login },
    { path: '/bag', exact: true, name: 'Store Bag', component: Bag },
    { path: '/checkout/address', exact: true, name: 'Address', component: Address },

]

export default route;
