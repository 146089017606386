import React, { Component } from "react";

import "../../css/toolbar.scss";
import {Badge, Button, Drawer} from "antd";
import { toast } from "react-toastify";
import { PrintLogs } from "../../LocalStorage";
import { getCartByUserid } from "../../services";

import { Link } from 'react-router-dom'; 

import {connect} from "react-redux";
import {getLogo} from "../../helpers";
const LogString = "Toolbar";

class Toolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolldir: "up",
      cartFlag: "inactive",
      userFlag: "inactive",
      email: "",
      password: "",
      bagcount: 0,
      visible:false
    };

    this.cartFlagStatus = this.cartFlagStatus.bind(this);
    this.userFlagStatus = this.userFlagStatus.bind(this);
  }
  componentDidMount() {
    var h1 = parseInt(this.refs.header.offsetHeight);
    window.addEventListener("scroll", this._calcScroll.bind(this, h1));
    if (
      this.props.userSession !== "" ||
      this.props.userSession !== null ||
      this.props.userSession !== undefined
    ) {
      // this._getCartItems();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this._calcScroll);
  }


  _calcScroll(h1) {
    try {
      var _window = window;
      var heightDiff = parseInt(h1);
      var scrollPos = _window.scrollY;
      if (scrollPos > heightDiff) {
        // here this means user has scrolled past your header,
        // you may rerender by setting State or do whatever
        // PrintLogs("down");
        if (this.state.scrolldir === "up")
          this.setState({
            scrolldir: "down"
          });
      } else {
        // PrintLogs("up");
        // here the user has scrolled back to header's territory,
        // it's optional here for you to remove the element on navbar as stated in the question or not
        if (this.state.scrolldir === "down")
          this.setState({
            scrolldir: "up"
          });
      }
    } catch (err) {
      console.log(err);
    }
  }

  cartFlagStatus() {
    if (this.state.cartFlag === "inactive") {
      this.setState({
        cartFlag: "active",
        userFlag: "inactive"
      });
    } else {
      this.setState({
        cartFlag: "inactive"
      });
    }
  }

  userFlagStatus() {

    if (
      this.props.userSession !== "" ||
      this.props.userSession !== null ||
      this.props.userSession !== undefined
    ) {
        this.setState({
        userFlag: "active"
      });
    }

  }



  _getCartItems() {
    if (
      this.props.userSession !== "" ||
      this.props.userSession !== null ||
      this.props.userSession !== undefined
    ) {
      getCartByUserid()
        .then(response => {
          PrintLogs(LogString, response);
          let prodArr = [];

          response.forEach(element => {
            let subsType = element.subscription_type;
            prodArr.push({
              cartId: element.cartId,
              productId: element.product_details.productId,
              title: element.product_details.title,
              qty: element.qty,
              price:
                subsType === "OT"
                  ? element.product_details.price
                  : element.product_details.subscription_price,
              img: element.product_details.assets[0].link
            });
          });
          this.props.updateCart(prodArr);
        })
        .catch(err => {
          PrintLogs(LogString, err);
          // toast.error(err.message)
          const errorcod = err.toString();
          if (errorcod.includes("401")) {
            this.props.clearProdCart();
            // this.userFlagStatus();
          }
        });
    } else {
      toast.error("login yourself first");
    }
  }


  signOut = () => {
    const {dispatch} = this.props
    dispatch({
      type: 'user/LOG_OUT'
    })
  }


  render() {
    const LOGO = getLogo()
    const logoPath =require(`../../assets/images/${LOGO}`)
    return (
      <header
        ref={"header"}
        className="header header-fixed header-fixed-on-mobile header-transparent"
        data-bkg-threshold="100"
        style={{
          backgroundColor:
            this.state.scrolldir === "up" ? "transparent" : "white"
        }}
      >
        <div className="header-inner px-3">
          <div className="row nav-bar mx-auto">
            <div className="column width-12 nav-bar-inner no-padding-on-mobile">

                <div className="logo" >
                  <div className="logo-div">


                    <img
                        src={logoPath}
                        className="ml-2"
                        alt="BATCH"
                        onClick={() => {this.props.history.push('/')}}
                    />
                  </div>

                </div>

                  <nav
                      className={` px-4 navigation nav-block secondary-navigation nav-right ${
                          this.state.scrolldir === "up" ? "active" : "inactive"
                      }`}
                  >
                    <ul>

                     
                      <li>


                            <Link
                                className="no-page-fade text-uppercase no-margin-bottom"
                                to = "/bag"
                                style={{ color: "black" }}
                                onClick={()=> this.setState({visible:true})}
                            >
                              <Badge count={this.props.bag.addedIds.length} style={{fontSize:"1rem"}}>
                              <i className="fas fa-shopping-bag"  style={{fontSize:"1.8rem"}}/>

                              </Badge>
                              {/*<span style={{fontSize:"1rem"}} className="mb-5 notification-icon">*/}
                              {/*  ({this.props.bag.addedIds.length})*/}
                              {/*</span>*/}
                            </Link>

                      </li>

                      <li>
                        <Link to={this.props.user.loggedIn? "/profile":"/drawer/login"}
                           className="no-page-fade text-uppercase no-margin-bottom mx-0 pr-2"
                        >
                          <i className="fas fa-user" />
                        </Link>

                      </li>
                      {
                        this.props.user.loggedIn &&
                        <li>
                          <Link to="/drawer/login" onClick={this.signOut}>
                            <i className="fas fa-sign-out-alt" />
                          </Link>
                        </li>
                      }
                    </ul>
                  </nav>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = ({bag,dispatch,user}) =>({bag,dispatch,user})
export default React.memo(connect(mapStateToProps)(Toolbar));
