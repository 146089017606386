import React from "react";
import {
  CART_DATA,
  PICKUP_CART,
  BOX_ID,
  USER_SESSION,
  TOTAL_CART_VALUE,
  SALES_TAX,
  CART_VALUE,
  TOTAL_ITEMS,
  LOGIN_REDIR,
  OVER_21,
  USER_SELECTED_ADDRESS,
  ORDER_NUMBER,
  NUMBER_OF_ITEMS_IN_BAG

} from "../constants";
import { SetValue, GetValue, GetValueArr, PrintLogs } from "../LocalStorage";

import { addToCart, getCartByUserid } from "../services";

export const AppContext = React.createContext();

const LogString = "App Provider";

class AppProvider extends React.Component {

  setUserAddress = data => {
    let address = {}
    try{
      address = JSON.parse(data)
    }
    catch{
      address = data
    }
    SetValue(USER_SELECTED_ADDRESS, address)
    this.setState({
      userAddress: address
    })

  }

  updateBagCount = (action,quantity) => {
    let value = this.state.numberOfItemsInBag;

    if(action == "add")
      value = value + quantity;
    else if(action == "delete")
      value = value - quantity;
    
    SetValue(NUMBER_OF_ITEMS_IN_BAG, value)
    this.setState({
      numberOfItemsInBag: value
    })
  }

setOrderNumber = data => {
  SetValue(ORDER_NUMBER, data)
    this.setState({
      orderNumber: data
    })
}
  setTotalItems = data => {
    SetValue(TOTAL_ITEMS, JSON.stringify(data))
    this.setState({
      totalItems: data
    })

  }


  setSalesTax = data => {
    SetValue(SALES_TAX, JSON.stringify(data))
    this.setState({
      SALES_TAX: data
    })

  }


  setTotalCartValue = data => {
    SetValue(TOTAL_CART_VALUE, JSON.stringify(data))
    this.setState({
      totalCartValue: data
    })

  }


  setCartValue = data => {
    SetValue(CART_VALUE, JSON.stringify(data))
    this.setState({
      cartValue: data
    })

  }

  //////////////////////////////////////// cart for pickup only box //////////////////////////////////////////////
  setPickupCartData = data => {
    SetValue(PICKUP_CART, JSON.stringify(data))
    this.setState({
      pickUpCart: data
    })
  }
  //////////////////////////////////////// End cart for pickup only box //////////////////////////////////////////////

  //////////////////////////////////////////// update local cart data ///////////////////////////////////////////
  updateProductData = data => {
    SetValue(BOX_ID, data.bundles[0].id);
    // SetValue(PRODUCT_ARR, data.bundles[0].pricePlanInfos)
    this.setState({
      boxID: data.bundles[0].id
      // productArray: data.bundles[0].pricePlanInfos,
    });
  };
  //////////////////////////////////////////// End update local cart data ///////////////////////////////////////////

  //////////////////////////////////////////// calculate total cart value ////////////////////////////////////////
  calcTotalPrice(temp) {
    let tempPrice = 0;
    let _totalitems = 0;
    for (let i = 0; i < this.state.cartData.length; i++) {
      tempPrice =
        tempPrice + this.state.cartData[i].price * this.state.cartData[i].qty;
      _totalitems = _totalitems + this.state.cartData[i].qty;
    }
    SetValue(TOTAL_CART_VALUE, tempPrice);
    SetValue(TOTAL_ITEMS, _totalitems)

    this.setState({
      totalCartvalue: tempPrice,
      totalitems: _totalitems
    });
  }
  //////////////////////////////////////////// End calculate total cart value ////////////////////////////////////////

  ////////////////////////////////////////////////// Clear cart  ////////////////////////////////////////////////////
  clearProdCart = () => {
    SetValue(CART_DATA, []);
    this.setState({
      cartData: []
    });
    this.calcTotalPrice(this.state.cartData);
  
  };
  ////////////////////////////////////////////////// End Clear cart  ////////////////////////////////////////////////////

 
  ////////////////////////////////////////////////// Update server cart API  ////////////////////////////////////////////////////
  updateCart = data => {
    PrintLogs("Context", data);
    SetValue(CART_DATA, JSON.stringify(data));
    this.setState({
      cartData: data
    });
    this.calcTotalPrice();
  };
  ////////////////////////////////////////////////// End Update server cart API  ////////////////////////////////////////////////////

   _addToBag =  (store, quantity, product) => {
      
      if (this.state.userSession != null) {

        let cartpayload  = {};
  
        cartpayload.quantity  = (quantity == null)?1:quantity;
        cartpayload.productId = product.productId;
        cartpayload.subType   = "";
        cartpayload.price     = product.price;
        cartpayload.subTotal = product.price;
        cartpayload.exciseTax = product.price;
        cartpayload.deliveryFees = product.price;
        cartpayload.processingFees = product.price;
        cartpayload.salesTax = product.price;
        cartpayload.subscription_type = "one-time";
        cartpayload.brand = product.brand;
        cartpayload.title = product.title;
        cartpayload.category = product.category;
        cartpayload.short_description= product.short_description;
        cartpayload.size = product.size;
        cartpayload.ordertype = "delivery"
        cartpayload.link = (product.assets != null && product.assets[0] != null) ? product.assets[0].link : "https://storage.cloud.google.com/ppproductimages/no-image.png"
      //   cartpayload.storeId = storeId
      if(cartpayload.ordertype == "delivery")
          cartpayload.storeId = store
      else{
        cartpayload.storeaddress = store.store_addrs[0].addr
        cartpayload.city = store.store_addrs[0].city
        cartpayload.state = store.store_addrs[0].state
        cartpayload.zip = store.store_addrs[0].zip
        cartpayload.lat = store.store_addrs[0].lat
        cartpayload.long = store.store_addrs[0].long
        cartpayload.storeId = store.storeId
      }
  
        addToCart(cartpayload)
          .then(response => {
            PrintLogs(LogString, response);

           return response
          })
          .catch(err => {
           
              const errcode = err.toString();
              if(errcode.includes('401'))
              {
                  //this.props.history.push('/login');
                  window.location.href = 
                  this.setLoginRedirect(window.location.pathname);
              }
              else{
                //toast.error(err.message);  
              }
              
          });
      } else {
        
        //this.props.history.push('/login');
       // this.setLoginRedirect(this.props.location.pathname);
       this.setLoginRedirect(window.location.pathname);
      }
    };
  //////////////////////////////////////////////////  Update user token  ////////////////////////////////////////////////////
  updateUserSession = response => {

    SetValue(USER_SESSION, response.token);
    this.setState({
      userSession: response.token
    });
  };
  //////////////////////////////////////////////////  End Update user token  ////////////////////////////////////////////////////

  //////////////////////////////////////////////////  Set Redirect after login  ////////////////////////////////////////////////////
  setLoginRedirect = str => {
    SetValue(LOGIN_REDIR, str);
    this.setState({
      loginRedirect: str
    });
  };
  //////////////////////////////////////////////////  End Set Redirect after login  ////////////////////////////////////////////////////

  //////////////////////////////////////////////////  Save User Age Check  ////////////////////////////////////////////////////
  setToggleModel = value => {
    this.setState({
      toggleModal: value
    });
    SetValue(OVER_21, value);
  };
  //////////////////////////////////////////////////  End Save User Age Check  ////////////////////////////////////////////////////

  state = {
    
    userSession: GetValue(USER_SESSION),
    pickUpCart: GetValueArr(PICKUP_CART) !== null ? GetValueArr(PICKUP_CART) : {},
    loginRedirect: GetValue(LOGIN_REDIR),
    toggleModal: GetValue(OVER_21) !== null ? GetValue(OVER_21) : false,
    cartData: GetValue(CART_DATA) !== null ? GetValueArr(CART_DATA) : [],
    totalCartValue: GetValue(TOTAL_CART_VALUE),
    setUserAddress: this.setUserAddress,
    updateBagCount: this.updateBagCount,
    totalItems: GetValue(TOTAL_ITEMS),
    cartValue: GetValue(CART_VALUE),
    salesTax: GetValue(SALES_TAX),
    numberOfItemsInBag: GetValue(NUMBER_OF_ITEMS_IN_BAG),
    setOrderNumber: this.setOrderNumber,
    updateProductData: this.updateProductData,
    updateSearch: this.updateSearch,
    clearProdCart: this.clearProdCart,
    _addToBag: this._addToBag,
    _getCartItems: this._getCartItems,
    updateCart: this.updateCart,
    updateUserSession: this.updateUserSession,
    setLoginRedirect: this.setLoginRedirect,
    setToggleModel: this.setToggleModel,
    setPickupCartData: this.setPickupCartData,
    userAddress: GetValue(USER_SELECTED_ADDRESS), 
    setTotalItems: this.setTotalItems,
    setCartValue: this.setCartValue,
    setSalesTax: this.setSalesTax,
    setTotalCartValue: this.setTotalCartValue
    
  };

  render() {
    // PrintLogs("state", this.state);
    return (
      <AppContext.Provider value={this.state}>
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

const AppConsumer = AppContext.Consumer;

///////////////////////////////////// component which wrap other component with context ////////////////////////////////
const withAppContext = Component => {
  return props => {
    return (
      <AppContext.Consumer>
        {({
          // productId,
          orderNumber,
          setOrderNumber,
          cartData,
          totalCartValue,
          setUserAddress,
          updateBagCount,
          totalItems, 
          cartValue,
          salesTax,
          loginRedirect,
          toggleModal,
          userSession,
          updateProductData,
          updateSearch,
          clearProdCart,
          _addToBag,
          _getCartItems,
          updateCart,
          updateUserSession,
          setLoginRedirect,
          setToggleModel,
          setPickupCartData,
          setTotalItems,
          setCartValue,
          setSalesTax,
          setTotalCartValue,
          userAddress,
          numberOfItemsInBag,
          setNumberOfItemsInBag
        }) => {
          return (
            <Component
              {...props}
              orderNumber={orderNumber}
              setOrderNumber = {setOrderNumber}
              cartData={cartData}
              totalCartValue={totalCartValue}
              totalItems = {totalItems}
              numberOfItemsInBag = {numberOfItemsInBag}
              setUserAddress = {setUserAddress}
              updateBagCount = {updateBagCount}
              salesTax = {salesTax}
              cartValue = {cartValue}
              userSession={userSession}
              loginRedirect={loginRedirect}
              toggleModal={toggleModal}
              updateProductData={updateProductData}
              updateSearch={updateSearch}
              clearProdCart={clearProdCart}
              _addToBag={_addToBag}
              _getCartItems={_getCartItems}
              updateCart={updateCart}
              updateUserSession={updateUserSession}
              setLoginRedirect={setLoginRedirect}
              setToggleModel={setToggleModel}
              setPickupCartData={setPickupCartData}
              setTotalItems = {setTotalItems}
              setCartValue  = {setCartValue}
              setTotalCartValue = {setTotalCartValue}
              setSalesTax = {setSalesTax}
              userAddress = { userAddress}
              setNumberOfItemsInBag = {setNumberOfItemsInBag}

            />
          );
        }}
      </AppContext.Consumer>
    );
  };
};
///////////////////////////////////// End component which wrap other component with context ////////////////////////////////

export { AppProvider, AppConsumer, withAppContext };
