import axios from "axios";
import { API_ENDPOINT} from "../../config";
import { USER_SESSION } from "../../constants";
import { GetValue, PrintLogs } from "../../LocalStorage";

const LogString = "Cart Service";


export const getCartItems = () =>{

    return new Promise((resolve, reject) => {
      const key = GetValue(USER_SESSION);
      axios({
        method: "get",
        url: `${API_ENDPOINT}/user/getCartItems`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${key}`
        },
      })
        .then(response => {
          if (response.data.success === true) {
            return resolve(response.data.result);
          } else {
            return reject(response.data.error);
          }
        })
        .catch(error => {
          // if (error.response.status === 400) {
          //   error.message = error.response.data.error;
          // }
          reject(error);
        });
    });
  
  }

export const addToCart = (payload) => {
    console.log("payload",payload)
    return new Promise((resolve, reject) => {
        const token = GetValue(USER_SESSION)
       
        axios({
            method: "post",
            url: `${API_ENDPOINT}/user/addCart`,
            headers:{
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            data: payload
        })
        .then((response) => {
            PrintLogs(LogString, response);

            if(response.data.success === true)
            {
                resolve(response.data.result)
            }
            else{

                reject(response.data.error)
            }

        })
        .catch(err => {
            PrintLogs(LogString, err)
            reject(err)
        })
    })
}


export const getCartHeaders = (payload) => {
    
    return new Promise((resolve, reject) => {

        const token = GetValue(USER_SESSION);
        
        axios({
            method: "post",
            url: `${API_ENDPOINT}/order/cartheaders`,
            headers:{
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            data: payload
        })
        .then(response => {

           
            PrintLogs(LogString, response);
            if(response.data.success === true)
            {
                resolve(response.data.result)
            }
            else
            {
                reject(response.data.error)
            }
        })
        .catch(err => {
            PrintLogs(LogString, err)
            reject(err)
        })
    })
}


export const getCartByUserid = () => {
    return new Promise((resolve, reject) => {
        const token = GetValue(USER_SESSION);
        
        axios({
            method: "get",
            url: `${API_ENDPOINT}/user/getCart`,
            headers:{
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        })
        .then(response => {
            PrintLogs(LogString, response);
            if(response.data.success === true)
            {
                resolve(response.data.result)
            }
            else{
                reject(response.data.error)
            }
        })
        .catch(err => {
            PrintLogs(LogString, err)
            reject(err)
        })
    })
}

export const removeCartItem = (cartId) => {
    return new Promise((resolve, reject) => {
        const token = GetValue(USER_SESSION);

        axios({
            method:"delete",
            url: `${API_ENDPOINT}/user/removeCartItem`,
            headers:{
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            data: {
                cartId: cartId
            }
        })
        .then( response => {
            PrintLogs(LogString, response)

            if(response.data.success === true)
            {
                resolve(response.data.result)
            }
            else{
                reject(response.data.error)
            }
        })
        .catch(err => {
            PrintLogs(LogString, err);
            reject(err)
        })
    })
}

export const updateCartItem = (payload) => {
    return new Promise((resolve, reject) => {
        const token = GetValue(USER_SESSION);
        let updateCartData = {}
        let attr_update = {}
        
        updateCartData.cartId = payload.cartId;
        attr_update.quantity = payload.quantity;
        attr_update.subTotal = payload.quantity*payload.price;
        updateCartData.attr_update = attr_update;
      
        axios({
            method:"put",
            url: `${API_ENDPOINT}/user/updateCart`,
            headers:{
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            data: updateCartData
        })
        .then( response => {
            PrintLogs(LogString, response)

            if(response.data.success === true)
            {
                resolve(response.data.result)
            }
            else{
                reject(response.data.error)
            }
        })
        .catch(err => {
            PrintLogs(LogString, err);
            reject(err)
        })
    })
}
