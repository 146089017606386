import axios from "axios";
import { API_ENDPOINT } from "../../config";
import { JWT_TOKEN } from "../../constants";
import { GetValue, PrintLogs } from "../../LocalStorage";

const LogString = "Product Service";

export const getAllBoxes = props => {

  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url: `${API_ENDPOINT}/getAllBoxes`,
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then( response => {
      PrintLogs(LogString, response);
      if(response.data.success === true)
      {
        resolve(response.data.result)
      }
      else{
        reject(response.data.error)
      }
    })
    .catch( err => {
      reject(err)
    })
  })
}

export const getBoxProduct = props => {
  
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url: `${API_ENDPOINT}/getBoxProd?productId=${props.productId}`,
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then( response => {
      PrintLogs(LogString, response);
      if(response.data.success === true)
      {
        resolve(response.data.result)
      }
      else{
        reject(response.data.error)
      }
    })
    .catch( err => {
      reject(err)
    })
  })
}

export const getAllProduct = props => {
  return new Promise((resolve, reject) => {
    PrintLogs(props.name + " " + props.type);
    // corps errror
    let name = "pause";
    let type = "OT";
    axios({
      method: "get",
      url: `${API_ENDPOINT}/searchProducts?boxName=${name}&boxType=${type}`,
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        PrintLogs(LogString + " bundle", response);
        if (response.status === 200) {
          return resolve(response.data.result);
        } else {
          return reject(response.message);
        }
      })
      .catch(error => {
        if (error.response.status === 400) {
          error.message = error.response.data.error;
        }
        reject(error);
      });
  });
};


export const getStoreProducts = props => {
  console.log("loading api pordcuts",API_ENDPOINT)
  return new Promise((resolve, reject) => {
    let url =""
    // if(props.storeId != null){
    //    url = API_ENDPOINT+'/store/catalog/'+props.storeId
    // }else{

    // }
    axios({
      method: "get",
      url: `${API_ENDPOINT}/store/catalog`,
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        console.log(response)
        PrintLogs(LogString + " bundle", response);
        if (response.status === 200) {
          return resolve(response.data.result);
        } else {
          return reject(response.message);
        }
      })
      .catch(error => {
        console.log(error)
        if (error.response.status === 400) {
          error.message = error.response.data.error;
        }
        reject(error);
      });
  });
};

export const getBoxDetails = productId => {
  return new Promise((resolve, reject) => {
    const key = GetValue(JWT_TOKEN);
    axios({
      method: 'get',
      url: `${API_ENDPOINT}/box/detail?productId=${productId}`,
      headers: {
        Authorization: "Bearer " + key
      }
    })
      .then(response => {
        if (response.data.success === true) {
          return resolve(response.data.result);
        } else {
          return reject(response.data.error);
        }
      })
      .catch(error => {
        return reject(error);
      });
  });
};


export const getProductDetails = productId => {
  return new Promise((resolve, reject) => {
    const key = GetValue(JWT_TOKEN);
    axios({
      method: 'get',
      url: `${API_ENDPOINT}/box/detail?productId=${productId}`,
      headers: {
        Authorization: "Bearer " + key
      }
    })
      .then(response => {
        if (response.data.success === true) {
          return resolve(response.data.result);
        } else {
          return reject(response.data.error);
        }
      })
      .catch(error => {
        return reject(error);
      });
  });
};


export const getBoxProducts = productId => {
  return new Promise((resolve, reject) => {
    const key = GetValue(JWT_TOKEN);
    axios({
      method: 'get',
      url: `${API_ENDPOINT}/box/products?bundleId=${productId}`,
      headers: {
        Authorization: "Bearer " + key
      }
    })
      .then(response => {
        if (response.data.success === true) {
          return resolve(response.data.result);
        } else {
          return reject(response.data.error);
        }
      })
      .catch(error => {
        return reject(error);
      });
  });
};

export const getBoxPriceplans = productId => {
  return new Promise((resolve, reject) => {
    const key = GetValue(JWT_TOKEN);
    axios({
      method: 'get',
      url: `${API_ENDPOINT}/box/priceplans?productId=${productId}`,
      headers: {
        Authorization: "Bearer " + key
      }
    })
      .then(response => {
        if (response.data.success === true) {
          return resolve(response.data.result);
        } else {
          return reject(response.data.error);
        }
      })
      .catch(error => {
        return reject(error);
      });
  });
};

export const checkZipAvail = (zip) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${API_ENDPOINT}/availZip?zipCode=${zip}`
    })
    .then( response => {
      PrintLogs(LogString, response)
      if(response.data.success)
      {
        resolve( response.data)
      }
      else{
        reject(response.data.error)
      }
    })
    .catch(error => {

      PrintLogs(LogString, error)
      reject(error)
    })
  })
}



export const getProductImageLink = (product) => {
  let imageObject = product&&product.assets.find(e => e.type === "photo" || e.type === "image");
  let link = imageObject ? imageObject.link : "";
  return link

}
