import React, {Suspense, useState, useCallback, useEffect} from 'react';
import {Switch, Route} from 'react-router-dom';


import routes, {drawerRoutes} from '../../routes';
import '../../css/defaultLayout.scss';
import {toast} from 'react-toastify';
import Sidebar from '../../components/Sidebar/Sidebar';
import Toolbar from "../../components/Toolbar";
import {connect} from "react-redux";
import Loader from "../../components/Loader";
import {Drawer} from "antd";
import Bag from "../../views/bag/Bag";

const Footer = React.lazy(() => import('../../components/Footer'));
const ErrorBoundary = React.lazy(() => import('../../components/ErrorBoundary/ErrorBoundary'));

const DefaultLayout = (props) => {
    const [sidebar, setSideBar] = useState(false);
    const [drawer, setDrawer] = useState(true);

    const changeStatus = useCallback(() => {
        setSideBar(!sidebar)
    }, [sidebar]);

    useEffect(() => {

        toast.configure({
            autoClose: 4000,
            draggable: true,

        });
    }, []);

    return (


        <div>

            <Sidebar sidebar={sidebar} onClickSidebar={changeStatus}/>
            <div

                style={{height: "100%", backgroundColor: 'white'}}>


                <Toolbar onClickSidebar={changeStatus} {...props} />


                <div className="wrapper-inner"
                     style={{isolation: 'isolate', minHeight: '100vh', backgroundColor: 'white'}}
                >
                    <main>

                        <div className="main-container content clearfix bkg-white">
                            <ErrorBoundary>
                                <Suspense fallback={<Loader/>}>
                                    <Switch>
                                        {
                                            routes.map((route, idx) => {
                                                return (<Route
                                                        key={idx}
                                                        path={route.path}
                                                        exact={route.exact}
                                                        name={route.name}
                                                        render={props => (
                                                            <route.component {...props} />
                                                        )}/>
                                                )
                                            })
                                        }
                                    </Switch>
                                </Suspense>
                            </ErrorBoundary>
                        </div>
                        {drawerRoutes.map(drawerRoute => {
                            return (
                                <Route key={drawerRoute.name} path={drawerRoute.path} exact={drawerRoute.exact} render={(props) => {
                                    setDrawer(true);
                                    return (
                                        <Drawer
                                            visible={drawer}
                                            onClose={() => {
                                                props.history.push('/')
                                            }}
                                        >
                                            <drawerRoute.component {...props} />
                                        </Drawer>
                                    )
                                }}/>
                            )
                        })}
                        <Route path="/bag" exact={true} render={(props) => {

                            setDrawer(true);


                            return (
                                <Drawer


                                    visible={drawer} onClose={() => {
                                    props.history.push('/')
                                }}
                                >
                                    <Bag {...props} />


                                </Drawer>
                            )
                        }}/>

                    </main>

                    <Footer/>
                </div>

            </div>
        </div>
    );
};

const mapStateToProps = ({user}) => ({user});

export default React.memo(connect(mapStateToProps)(DefaultLayout));
