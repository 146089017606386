import React from 'react';
import PropTypes from 'prop-types';
import {Carousel} from "antd";
import ProductCard from "../ProductCard";
import {connect} from "react-redux";
import './style.scss'
/**
 * @author Pranav Singhal <pranavsinghal96@gmail.com>
 * @author [Pranav Singhal](https://github.com/pranav-singhal)
 * @createdOn  24-2020-04, Fri, 21:17
 */
Recommendations.propTypes = {
    products: PropTypes.object.isRequired
};

function Recommendations({products}) {
    return (
        <Carousel>
            {products.recommendationIds.map((productId, index) => {
                const {productsByIds} = products
                return (
                    <ProductCard className="col-12 px-0" product={productsByIds[productId]} key={productId} />
                );
            })}
        </Carousel>
    );
}

const mapStateToProps = ({products}) => ({products})

export default connect(mapStateToProps)(Recommendations);

