const LOG_SHOW = false;
const JWT_TOKEN = "jwtToken";
const REFRESH_TOKEN = "refreshToken";
const USER_ACCOUNT_NUMB = "userAccountNumber";
const BOX_ID = "box_id";
const PRODUCT_ID = "product_id";
const PRODUCT_ARR = "product_arr";
const SEARCH_STR = "search_str";
const CART_DATA = "cart_data";
const PICKUP_CART = "pickup_cart";
const TOTAL_CART_VALUE = "totalCartValue";
const USER_SESSION = "user_session";
const USER_NAME = "user_name";
const ACCOUNT_NUM = "account_number";
const LOGIN_REDIR = "login_driect";
const OVER_21 = "over_21";
const TOTAL_ITEMS = 'totalitems'
const USER_SELECTED_ADDRESS = "selected_address"
const CART_VALUE = "cartValue"
const SALES_TAX = "salesTax"
const ORDER_NUMBER='orderNumber'
const NUMBER_OF_ITEMS_IN_BAG="numberOfItemsInBag"
const LOGO = 'logo-batch.png'


export {
  LOGO,
  NUMBER_OF_ITEMS_IN_BAG,
  LOG_SHOW,
  ORDER_NUMBER,
  JWT_TOKEN,
  REFRESH_TOKEN,
  USER_ACCOUNT_NUMB,
  PRODUCT_ARR,
  BOX_ID,
  PRODUCT_ID,
  SEARCH_STR,
  CART_DATA,
  PICKUP_CART,
  TOTAL_CART_VALUE,
  USER_SESSION,
  USER_NAME,
  ACCOUNT_NUM,
  LOGIN_REDIR,
  OVER_21,
  TOTAL_ITEMS,
  USER_SELECTED_ADDRESS,
  CART_VALUE,
  SALES_TAX
};
