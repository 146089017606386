import React,{useState,useEffect} from 'react';
import packageJson from '../../../package.json'


/**
 * @author Pranav Singhal <pranavsinghal96@gmail.com>
 * @author [Pranav Singhal](https://github.com/pranav-singhal)
 * @createdOn  4-2020-05, Mon, 20:29
 */
CacheBuster.propTypes = {

};


global.appVersion = packageJson.version;
const semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g);

    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift());

        const b = Number(versionsB.shift());
        // eslint-disable-next-line no-continue
        if (a === b) continue;
        // eslint-disable-next-line no-restricted-globals
        return a > b || isNaN(b);
    }
    return false;
};
function CacheBuster({children}) {
    const [loading,setLoading] = useState(true)
    const refreshCacheAndReload = () => {
        console.log('Clearing cache and hard reloading...')
        if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(function(names) {
                for (let name of names) caches.delete(name);
            });
        }
        // delete browser cache and hard reload
        window.location.reload(true);
    }
    useEffect(()=>{
        fetch('/meta.json')
            .then((response) => response.json())
            .then((meta) => {
                console.log("inside here")
                const latestVersion = meta.version;
                const currentVersion = global.appVersion;
                console.log(latestVersion,currentVersion)

                const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
                if (shouldForceRefresh) {
                    console.log(`We have a new version - ${latestVersion}. Should force refresh`);
                    setLoading(false)
                    refreshCacheAndReload()

                } else {
                    console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
                    setLoading(false)

                }

            }).catch(err => {
            console.log(err)
        })
            .finally(()=>{
                setLoading(false)
            })

    },[])
    console.log(loading)


    return (
        
            !loading && children

    );
}

export default CacheBuster;
