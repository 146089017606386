import {combineReducers} from "redux";
import bag from "../bag/reducers";
import products from '../products/reducers'
import user from '../user/reducers'
import organisation from '../organisation/reducers'

export default () =>
    combineReducers({
        organisation,
        bag,
        products,
        user
    })
