import React from "react";
import { Link } from "react-router-dom";
import ProfileMenu from "../ProfileMenu";

function Sidebar(props) {

  return (
    <aside
      className={`  ${props.sidebar === true ? " side-navigation-wrapper enter-right reveal-reset no-scrollbar active reveal":"side-navigation-wrapper  enter-right"}`}
      data-no-scrollbar
      data-animation="reveal"
      
    >
      <div className="side-navigation-scroll-pane">
        <div className="side-navigation-inner">
          <div className="side-navigation-header">
            <div
              className="navigation-hide side-nav-hide"
              onClick={props.onClickSidebar}
            >
              <a  className="ml-auto">
                <span className="icon-cancel medium"></span>
              </a>
            </div>
          </div>

          <nav className="side-navigation center">
            <ul>
              <ProfileMenu />
            </ul>
          </nav>

        </div>
      </div>
    </aside>
  );
}

export default Sidebar;
