import { all } from 'redux-saga/effects'
import products from '../products/sagas'
import bags from '../bag/sagas'
import user from '../user/sagas'
import organisation from '../organisation/sagas'

export default function* rootSaga() {
    yield all([
        organisation(),
            products(),
        bags(),
        user()

    ])
}
