import React from 'react';
import 'antd/dist/antd.css';
import './style.scss'
import {Spin} from "antd";

/**
 * @author Pranav Singhal <pranavsinghal96@gmail.com>
 * @author [Pranav Singhal](https://github.com/pranav-singhal)
 * @createdOn   23-03-2020, 19:19
 */


function Loader() {
    return (
        <div className="col-6 offset-6 mt-5">
            <Spin size="large"  />
        </div>
    );
}

export default Loader;
