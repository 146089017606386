import React from 'react';

import {Button} from "antd";
import {connect} from "react-redux";
import './style.scss'

/**
 * @author Pranav Singhal <pranavsinghal96@gmail.com>
 * @author [Pranav Singhal](https://github.com/pranav-singhal)
 * @createdOn  18-2020-05, Mon, 16:52
 */
ClearBag.propTypes = {

};

function ClearBag({dispatch}) {
    return (
        <Button
            className="float-right mt-0 p-2 clear-bag"
            onClick={()=>{
                dispatch({
                    type: "bag/CLEAR_BAG"
                })
            }}
        >
            <i className="fas fa-times mr-2" />
            Clear Bag

        </Button>
    );
}

export const mapStateToProps = ({dispatch}) => ({dispatch})

export default connect(mapStateToProps)(ClearBag);
