import React from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";

/**
 * @author Pranav Singhal <pranavsinghal96@gmail.com>
 * @author [Pranav Singhal](https://github.com/pranav-singhal)
 * @createdOn   21-03-2020, 13:57
 */


function ProfileMenu({user,dispatch}) {

    const signOut = () => {
        dispatch({
            type: 'user/LOG_OUT'
        })
    }
    const {loggedIn} = user;
    if (loggedIn) {
        return (
            <>
                <li>
                    <Link to="/profile" >
                        Profile
                    </Link>
                </li>
                <li>

                    <Link to="/orders" >
                        My Orders
                    </Link>
                </li>
                <li>
                    <Link to="/login" onClick={signOut}>
                        Sign Out
                    </Link>
                </li>
            </>
        )
    }
    return (
        <>
        <li>
            <Link to="/login" >
            Sign In
            </Link>

        </li>
            <li>
                <Link to="/register" >
                    Register
                </Link>
            </li>
            </>

    )
}

const mapStateToProps = ({user,dispatch}) => ({user,dispatch});

export default connect(mapStateToProps)(ProfileMenu);
