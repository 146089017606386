import { login, registerUser, addToWish } from './auth';
import { getAllBoxes, getAllProduct, getBoxProduct, getProductDetails, getBoxDetails, getBoxPriceplans, getBoxProducts, getStoreProducts, checkZipAvail } from './product';
import { getOrderHistory,  validateCoupon, getOrderAddress, getOrderLines, getOrderPaymentDetails, getPayment, createOrder, getOrderHeader} from './order';
import { getProfileData, updateProfileData, getUserAddresses, addUserAddress, updateUserAddress, deleteUserAddress, addGovernmentId,getGovernmentIDs } from './profile';
import { addToCart, getCartByUserid, removeCartItem, updateCartItem , getCartItems, getCartHeaders} from './cart';
import { getGeocodeFromAddress } from './locations';
import { getStoreDetail, getStoreByLatLng } from './store'

export {
    addGovernmentId,
    getGovernmentIDs,
    getStoreProducts,
    createOrder,
    login,
    registerUser,
    addToWish,
    getAllBoxes,
    getBoxProduct,
    getAllProduct,
    getBoxDetails,
    checkZipAvail,
    getProfileData,
    updateProfileData,
    getOrderHistory,
    validateCoupon, 
    addToCart,
    getCartByUserid,
    removeCartItem,
    updateCartItem,
    getStoreDetail,
    getStoreByLatLng,
    getGeocodeFromAddress,
    getCartItems,
    getUserAddresses,
    getCartHeaders,
    getPayment,
    getBoxPriceplans,
    getBoxProducts,
    getProductDetails,
    addUserAddress,
    updateUserAddress,
    deleteUserAddress,  
    getOrderHeader,
    getOrderAddress,
    getOrderLines,
    getOrderPaymentDetails
}