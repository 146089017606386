Array.prototype.unique = function() {
    var a = this.concat();
    for(var i=0; i<a.length; ++i) {
        for(var j=i+1; j<a.length; ++j) {
            if(a[i] === a[j])
                a.splice(j--, 1);
        }
    }

    return a;
};

export const getLogo = () => {
    if(window.location.href.includes('caliplug')){
        return 'cali-logo.png'
    }
    if(window.location.href.includes('crown')){
        return 'crownlogo.png'
    }
    if(window.location.href.includes('cubalibre')){
        return 'cubalibre.png'
    }
    if(window.location.href.includes('mjmd')){
        return 'mjmd.png'
    }

    return 'logo-batch.png'
}

export const getSubdomain = () => {
    if(window.location.href.includes('caliplug')){
        return 'caliplug'
    }
    if(window.location.href.includes('crown')){
        return 'crown'
    }
    if(window.location.href.includes('cubalibre')){
        return 'cuba'
    }
    if(window.location.href.includes('mjmd')){
        return 'mjmd'
    }

    return 'batch'
}

export const showProductsBySubDomain = (productIds, productsByIds) => {
    const subDomain=getSubdomain()
    const subDomainProducts = productIds.filter(availableProductIds =>productsByIds[availableProductIds].brand.toLowerCase().includes(subDomain)  )
    return subDomainProducts.concat(productIds).unique()
}

const milesToMetres = (miles) => miles*1609.34
const withinRange = (difference,range) => difference <=range
const rangeInMiles = 15
const rangeInMetres = milesToMetres(rangeInMiles)

export const verifyZipCode  = (zipCode) => {

    const  service = new window.google.maps.DistanceMatrixService();
    const origin1 = '15374 Dickens St, Sherman Oaks, CA 91403';
    const origin2 = zipCode.toString();

    return new Promise((resolve, reject) => {
        service.getDistanceMatrix(
            {
                origins: [origin1],
                destinations: [
                    origin2
                ],
                travelMode: 'DRIVING',
                unitSystem: window.google.maps.UnitSystem.IMPERIAL
            }, (result,status)=>{


                if(status ==='OK'){
                    const resultElement = result.rows[0].elements[0]


                    if (resultElement.status === 'NOT_FOUND' || resultElement.status==='ZERO_RESULTS'){

                        reject('Sorry, We are Currently not delivering to your zipcode')
                    }else{

                        const distInMetres = resultElement.distance.value
                        

                        const isWithinRange =withinRange(distInMetres,rangeInMetres)

                        isWithinRange? resolve("We deliver to your address."): reject('Sorry, We are Currently not delivering to your zipcode')
                    }

                }else{
                    reject("Some Error occurred, please try again")
                }

            });
    })
}


export const splitAddress = (address) => {
    const addressArr = address.split(',')
    const stateZip = addressArr[addressArr.length-2].split(" ")
    const state = stateZip[stateZip.length-2]
    const zipCode  = stateZip[stateZip.length-1]
    const city = addressArr[1]
    const address1 = addressArr[0]
    return {state,city,zipCode,address1}

}


export const filterOptions = [
    {
        "value": "Flower",
        "html": "Flower"
    },
    {
        "value": "Pre-Roll Flower",
        "html": "Pre-Rolls"
    },
    {
        "value": "Concentrates",
        "html": "Concentrates"
    },
    {
        "value": "Concentrates Edible",
        "html": "Edibles"
    },
    {
        "value": "Flower Bundle",
        "html": "Bundles"
    },


    // {
    //     "value": "Concentrates Topical",
    //     "html": "Topical"
    // },

]
