import React, { useEffect, Fragment, useState } from "react";
import { Descriptions } from "antd";
import './Checkout.scss'

import { withAppContext } from "../../ContextProvider/AppProvider";
import { connect } from "react-redux";
import CartItem from "../../components/CartItem";
import Loader from "../../components/Loader";
import Recommendations from "../../components/Recommendations";
import EmptyCart from "../../components/EmptyCart";
import ClearBag from "../../components/ClearBag";
const MAX_ALLOWED_SUBTOTAL = 1000
const Bag = props => {

    const [total, setTotal] = useState({ excisePercent: 15, subTotal: 0 });
    const [subTotal, setSubTotal] = useState(0);

    useEffect(() => {

        if (props.bag && !props.products.loading) {
            const newSubTotal = props.bag.addedIds.reduce((total, currentItemId) => {
                return total + props.products.productsByIds[currentItemId].price * props.bag.addedItemsByIds[currentItemId].quantity
            }, 0);
            setSubTotal(newSubTotal);
            setTotal({ ...total, subTotal: newSubTotal })
        }
    }, [props.bag, props.products.loading]);

    const getButtonText = () => {
        if (subTotal) {
            if (subTotal < 50) {
                return "A minimum order of $50 is required for delivery"
            }

            if (subTotal > MAX_ALLOWED_SUBTOTAL) {
                return ` A maximum order of $${MAX_ALLOWED_SUBTOTAL} can be placed for delivery`
            }
        }
        return "Checkout"
    };


    useEffect(() => {
        let deliveryFee;
        (subTotal <= 100 && subTotal >= 50) ? deliveryFee = 8 : deliveryFee = 0;
        setTotal({ ...total, deliveryFee })
    }, [subTotal]);


    const handleCheckout = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!props.user.loggedIn) {
            props.history.push({
                pathname: "/drawer/login",
                state: {

                    from: props.location.pathname + props.location.search
                }
            })
        }
        if (props.user.loggedIn) {
            props.history.push("/checkout/address")
        }
    };

    const SubTotal = () => {
        const { Item } = Descriptions;
        return (
            <Descriptions bordered column={1}>
                <Item label="Subtotal">
                    $ {subTotal}
                </Item>
            </Descriptions>
        )
    };

    return (
        <Fragment>
            {props.user.loading && props.products.loading ? <Loader /> :
                <div className="container-fluid mt-5  px-5" style={{ height: "100%" }}>
                    <div className="col-12  pt-4 ">
                        <div className="row">
                            <div className="col-12">
                                <h3 className="text-center capitalize">
                                    Your Bag

                                </h3>
                            </div>
                        </div>
                        <div className="col-12">
                            <p className="text-center">
                                <small>
                                    All applicable discounts will be applied at checkout
                                </small>
                            </p>
                        </div>
                        {
                            props.bag.addedIds.length === 0 ?
                                <EmptyCart />
                                :
                                <div className="row">

                                    <div className="col-12">
                                        <h4 className="pl-2 pb-0 font-weight-bold mt-2 capitalize ">
                                            Bag Details
                                            <ClearBag />
                                        </h4>
                                        <div className="m-2 px-5 ">
                                            {
                                                props.bag.addedIds.map((addedProductId, index) => <CartItem
                                                    key={index.toString()}
                                                    item={props.bag.addedItemsByIds[addedProductId]}
                                                    productId={addedProductId} />)
                                            }
                                        </div>

                                    </div>
                                    <div className="col-12">
                                        <h4 className="pl-2 pb-0 font-weight-bold capitalize ">
                                            Order Summary
                                        </h4>

                                        <SubTotal />


                                    </div>
                                    <div className="col-12 mt-5">
                                        <h4 className="pl-2 pb-0 font-weight-bold capitalize ">
                                            You may also like
                                        </h4>
                                        <Recommendations />
                                    </div>
                                </div>
                        }
                    </div>
                    <div className="col-12">
                        <p className="text-center">
                            <small>
                                All applicable discounts will be applied at checkout
                            </small>
                        </p>
                    </div>

                    <button
                        className="checkout-btn"
                        disabled={subTotal < 50 || subTotal > MAX_ALLOWED_SUBTOTAL}
                        onClick={handleCheckout}
                    >
                        {getButtonText()}
                    </button>
                </div>
            }
        </Fragment>
    );
};

const mapDataToProps = ({ bag, products, user }) => ({ bag, products, user });
export default withAppContext(connect(mapDataToProps)(Bag));
