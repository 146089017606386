import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'jquery/dist/jquery/jquery.min.js';
import createSagaMiddleware from 'redux-saga'
import sagas from './redux/sagas'
import logger from 'redux-logger'
import './css/pauseplay.scss';
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import * as serviceWorker from './serviceWorker';
import reducers from "./redux/reducers";
console.disableYellowBox = true;

const sagaMiddleware = createSagaMiddleware()
const middlewares = [sagaMiddleware,logger]
const store = createStore(reducers(),compose(applyMiddleware(...middlewares)))
sagaMiddleware.run(sagas)
ReactDOM.render(
    <Provider store={store}>

    <App />
    </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
