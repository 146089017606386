import {call,put,takeEvery,all} from 'redux-saga/effects';
// import {login} from "../../services/auth";
import actions from './actions';
import {toast} from "react-toastify";
import {LOAD_ALL_PRODUCTS} from "../products/sagas";
import {getUserDetails} from "../../api";
import {login} from "../../api/";


export function* LOG_IN({payload}) {
    yield put({type:actions.SET_STATE,payload:{loading:true}})

    const {email,password} = payload

    try {
    const response = yield call(login,email,password)
        const {token}=response
        localStorage.setItem("user_session",token)
        yield put({type:actions.LOAD_CURRENT_ACCOUNT,payload:{token}})
    }catch (error) {
        toast.error(error)
    }finally {
        yield put({type: actions.SET_STATE,payload:{loading:false}})
    }


}

export function* LOAD_CURRENT_ACCOUNT(action) {
    yield put({type: actions.SET_STATE,payload:{loading:true}})
    // yield put({type: 'products/SET_STATE',payload:{loading:true}})
    const token =    localStorage.getItem('user_session') || (action&&action.payload &&action.payload.token)
    localStorage.setItem("user_session",token)

    if(token){
        try{
        const response = yield(call(getUserDetails))

            yield put({type: 'bag/SYNC_BAG'})
            yield put({type:actions.SET_STATE,payload:{loggedIn:true,loading:false,...response}})

        }catch (error) {
            if(error === 'jwt malformed'){
                yield put({type: 'user/LOG_OUT'})
            }
        }finally {
            yield put({type: actions.SET_STATE,payload:{loading:false}})
            // yield put({type: 'products/SET_STATE',payload:{loading:false}})
        }


    }
    if(!token){

        yield put({type: 'user/LOG_OUT'})
    }

}
export function* LOG_OUT() {
    yield put({type:actions.SET_STATE,payload:{loading:true}})
    localStorage.setItem("user_session","")
    yield put({type:actions.SET_STATE,payload:{loggedIn:false,loading:false}})
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.LOG_IN,LOG_IN),
        takeEvery(actions.LOAD_CURRENT_ACCOUNT,LOAD_CURRENT_ACCOUNT),
        takeEvery(actions.LOG_OUT,LOG_OUT),
        LOAD_CURRENT_ACCOUNT()
    ])
}
