import { all, put, call,takeEvery } from 'redux-saga/effects'

import actions from "./actions";
import {showProductsBySubDomain} from "../../helpers";
import {getRecommendations, getStoreCatalog} from "../../api/organisation";


export function* LOAD_ALL_PRODUCTS(action) {
    const {payload:{storeApiKey}} = action
    yield put({ type: actions.SET_STATE, payload: { loading:true } })
    const response = yield call(getStoreCatalog,storeApiKey)
    const availableProductsByIds = {}
     response.map(product => {
            availableProductsByIds[product.productId] = {...product}
    })
    const recommendations = yield call(getRecommendations,storeApiKey)
    const recommendationIds = recommendations.map((recommendation) => {
        availableProductsByIds[recommendation.productId] = {...recommendation}
        return recommendation.productId
    })

    const availableProductIds = response.map(product => product.productId)
    const availableProductIdsBySubDomain = showProductsBySubDomain(availableProductIds, availableProductsByIds)

    yield put({ type: actions.SET_STATE, payload: { filteredProductIds:availableProductIdsBySubDomain ,productIds:availableProductIdsBySubDomain,productsByIds:availableProductsByIds,recommendationIds } })
    yield put({ type: actions.SET_STATE, payload: { loading:false } })

}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.FETCH_PRODUCTS,LOAD_ALL_PRODUCTS)
    ])
}
