import actions from "./actions";

const initialState = {
    loggedIn :false,
    loading:false,
    addresses: [],
    government_ids: []
}


/**
 * @namespace Reducers
 *
 */
/**
 *
 *
 * The reducer for the user state
 *
 * @author Pranav Singhal <pranavsinghal96@gmail.com>
 * @author [Pranav Singhal](https://github.com/pranav-singhal)
 *
 * @function userReducer
 * @memberof Reducers
 * @param {Object} state - the state before performing an action
 * @param {String} action - the action to be performed on the state
 *@return {Object} - new state after performing the action

 * */
export default function userReducer(state=initialState,action) {

    const {type,payload} = action
    switch (type) {
        case actions.SET_STATE:
            return {...state,...payload}
        default:
            return {...state}
    }

}
