import actions from "./actions";

const initialState = {
    loading:false,
    productIds: [],
    filteredProductIds: [],
    recommendationIds:[],
    selectedCategories:[],
    productsByIds: {}
}
/**
 * @author Pranav Singhal <pranavsinghal96@gmail.com>
 * @author [Pranav Singhal](https://github.com/pranav-singhal)
 */


/**
 *
 *  A function in Reducers
 *
 * @function productsReducer
 * @memberof Reducers
 * @param {Object} state - the state before performing an action
 * @param {String} action - the action to be performed on the state
 *@return {Object} - new state after performing the action

 * */

export default function productsReducers(state=initialState,action) {
    switch (action.type) {
        case actions.SET_STATE:
            return {...state,...action.payload}
        default:
            return {...state}

    }

}
