
import { API_ENDPOINT } from '../../config'
import axios from 'axios';

// APi to get stores by zip code
export const getStoreDetail = storeId => {
 
    return new Promise((resolve, reject) => {
        
        axios({
            method: 'get',
            url: `${API_ENDPOINT}/store/${storeId}`,
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then( response => {
             if(response.data.success === true)
             {
                resolve(response.data.result)
             }
             else{
                reject(response.data.error)
             }  
        })
        .catch(err =>{
            reject(err)
        })

    })
}

// APi to get stores by Lat , Lng of Zipcode
export const getStoreByLatLng = (lat, long) => {
    lat = 34.21050850
    long = -118.56800310
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: `${API_ENDPOINT}/getStoreNearUser?lat=${lat}&lng=${long}`,
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then( response => {
            if(response.data.success === true)
            {
                resolve(response.data.result)
            }
            else{
                reject(response.data.error)
            }
        })
        .catch( err => {
            reject(err)
        })
    })
}