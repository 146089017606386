import axios from "axios";
import { API_ENDPOINT } from "../../config";
import { USER_SESSION } from "../../constants";
import { GetValue, PrintLogs } from "../../LocalStorage";

const LogString ="Order Service";


export const createOrder = (payload) => {
  console.log(payload)
  return new Promise((resolve, reject) => {

    const key = GetValue(USER_SESSION);

    axios({
      method: "post",
      url: `${API_ENDPOINT}/order/create`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`
      },
      data: {
       payload
      }
    })
    .then(response => {
      PrintLogs(LogString,response);
      if (response.status === 200) {
        return resolve(response.data);
      } else {
        return reject(response.message);
      }
    })
    .catch(error => {
      PrintLogs(LogString, error.response);
      if (error.response.status === 400) {
        error.message = error.response.data.error;
      }
      reject(error.response.data);

    });
  });
  
}

export const validateCoupon = (data, accNum, coupon) => {
  return new Promise((resolve, reject) => {
    const key = GetValue(USER_SESSION);
    axios({
      method: "post",
      url: `${API_ENDPOINT}/user/validateCoupon`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`
      },
      data: {
        payload: {
          couponCode: coupon,
          orders: data
        }
      }
    })
      .then(response => {
        PrintLogs(LogString,response);
        if (response.status === 200) {
          return resolve(response.data);
        } else {
          return reject(response.message);
        }
      })
      .catch(error => {
        PrintLogs(LogString, error.response);
        if (error.response.status === 400) {
          error.message = error.response.data.error;
        }
        reject(error.response.data);
      });
  });
};

export const getPayment = () => {

  const key = GetValue(USER_SESSION);

  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url: `${API_ENDPOINT}/order/payment`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`
      }
    })
      .then(response => {
        if (response.status === 200) {
          return resolve(response.data);
        } else {
          return reject(response.message);
        }
      })
      .catch(error => {
        if (error.response.status === 400) {
          error.message = error.response.data.error;
        }
        reject(error);
      });
  });

}

export const getOrderHistory = () => {
  const key = GetValue(USER_SESSION);
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url: `${API_ENDPOINT}/user/getAllOrder`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`
      }
    })
      .then(response => {
        if (response.status === 200) {
          return resolve(response.data);
        } else {
          return reject(response.message);
        }
      })
      .catch(error => {
        if (error.response.status === 400) {
          error.message = error.response.data.error;
        }
        reject(error);
      });
  });
};


export const getOrderHeader = (orderId) => {
  const key = GetValue(USER_SESSION);
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url: `${API_ENDPOINT}/user/getOrderHeaderByOrderNumber`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`
      },
      data: {
        orderId: orderId
      }
    })
      .then(response => {
        if (response.status === 200) {
          return resolve(response.data.result);
        } else {
          return reject(response.message);
        }
      })
      .catch(error => {
        if (error.response.status === 400) {
          error.message = error.response.data.error;
        }
        reject(error);
      });
  });
};


export const getOrderAddress = (orderId) => {
  const key = GetValue(USER_SESSION);
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url: `${API_ENDPOINT}/user/getOrderAddressByOrderNumber`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`
      },
      data: {
        orderId: orderId
      }
    })
      .then(response => {
        if (response.status === 200) {
          return resolve(response.data.result);
        } else {
          return reject(response.message);
        }
      })
      .catch(error => {
        if (error.response.status === 400) {
          error.message = error.response.data.error;
        }
        reject(error);
      });
  });
};


export const getOrderLines = (orderId) => {
  console.log(orderId)
  const key = GetValue(USER_SESSION);
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url: `${API_ENDPOINT}/user/getOrderLinesByOrderNumber`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`
      },
      data: {
        orderId: orderId
      }
    })
      .then(response => {
        console.log(response)
        if (response.status === 200) {
          return resolve(response.data.result);
        } else {
          return reject(response.message);
        }
      })
      .catch(error => {
        if (error.response.status === 400) {
          error.message = error.response.data.error;
        }
        reject(error);
      });
  });
};


export const getOrderPaymentDetails = (orderId) => {
  const key = GetValue(USER_SESSION);
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url: `${API_ENDPOINT}/user/getOrderPaymentDetailsByOrderNumber`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`
      },
      data: {
        orderId: orderId
      }
    })
      .then(response => {
        if (response.status === 200) {
          return resolve(response.data.result);
        } else {
          return reject(response.message);
        }
      })
      .catch(error => {
        if (error.response.status === 400) {
          error.message = error.response.data.error;
        }
        reject(error);
      });
  });
};
