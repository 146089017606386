import actions from './actions'

const initialState = {
    addedIds: [],
    addedItemsByIds: {}
};

const addToBag = (productId, state) => {
    const {addedIds, addedItemsByIds} = state;

    if (!addedIds.includes(productId)) {
        addedIds.push(productId);
        addedItemsByIds[productId] = {};
        addedItemsByIds[productId].quantity = 1;
        const newState = {addedIds, addedItemsByIds};
        localStorage.setItem("bag",JSON.stringify(newState))
        return {...newState}
    } else {
        localStorage.setItem("bag",JSON.stringify({...state}))
        return {...state}
    }
};
const deleteFromBag = (productId, state) => {
    const {addedIds, addedItemsByIds} = state;
    addedIds.splice(addedIds.indexOf(productId), 1);
    console.log(addedIds)
    delete addedItemsByIds[productId];
    const newState = {addedIds, addedItemsByIds};
    localStorage.setItem("bag",JSON.stringify(newState))
    return {...newState}
};

const toggleQuantity = (productId, newQuantity, state) => {
    const {addedItemsByIds} = state;
    addedItemsByIds[productId].quantity = newQuantity;
    const newState = {...state,addedItemsByIds}
    localStorage.setItem("bag",JSON.stringify(newState))
    return {...newState}
};


const setState = (state, payload) => {

    const newState = {...state,...payload}
    localStorage.setItem("bag",JSON.stringify(newState))
    return {...newState}
}

export default function bagReducer(state = initialState, action) {
    const {payload, type} = action;
    switch (type) {
        case actions.SET_STATE:
            return setState(state,payload);
        case actions.ADD_TO_BAG:
            return addToBag(payload.productId, state);
        case actions.DELETE_FROM_BAG:
            return deleteFromBag(payload.productId, state);
        case actions.TOGGLE_QUANTITY:
            return toggleQuantity(payload.productId, payload.newQuantity, state);
        default:
            return {...state}
    }
}
