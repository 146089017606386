import {all, takeEvery, put, call} from 'redux-saga/effects'
import actions from "./actions";
import {
    fetchDiscounts,
    fetchStoreApiForUser,
    fetchStoresForTenant,
    getTenantDetails
} from "../../api/organisation";


export function* FETCH_TENANT_DETAILS() {
    yield put({type: 'user/SET_STATE', payload: {loading: true}})
    yield put({type: 'products/SET_STATE', payload: {loading: true}})

    const {apiKey} = yield call(getTenantDetails)

    localStorage.setItem('tenantApiKey', apiKey)
    yield put({
        type: 'organisation/SET_STATE', payload: {tenantApiKey: apiKey}
    })

    const discounts = yield call(fetchDiscounts,'BULK_DISCOUNT')
    console.log(discounts)
    yield put({type:actions.SET_STATE,payload:{discounts:[...discounts]}})
    yield put({type: 'user/SET_STATE', payload: {loading: false}})
    yield put({type: actions.FETCH_STORES_FOR_TENANT})


}

export function* FETCH_STORES_FOR_TENANT() {
    yield put({type: 'user/SET_STATE', payload: {loading: true}})
    const tenantApiKey = localStorage.getItem('tenantApiKey')
    const stores = yield call(fetchStoresForTenant, tenantApiKey)
    yield put({
        type: 'organisation/SET_STATE', payload: {stores}
    })
    yield put({type: 'user/SET_STATE', payload: {loading: false}})
    // this logic will be removed once a user has the ability to select the store
    // for now we are defaulting to one store
    yield put({type: actions.FETCH_STORE_FOR_USER,payload: {storeId: stores[0].storeId}})

}

export function* FETCH_STORE_FOR_USER(action) {
    yield put({type: 'user/SET_STATE', payload: {loading: true}})

    const {payload:{storeId}} = action

    const tenantApiKey = localStorage.getItem('tenantApiKey')
    const {apiKey} = yield call(fetchStoreApiForUser,storeId,tenantApiKey)
    yield put({
        type: 'organisation/SET_STATE', payload: {storeApiKey: apiKey}
    })
    localStorage.setItem("storeApiKey",apiKey)
    yield put({type: 'user/SET_STATE', payload: {loading: false}})
    yield put({
        type: 'products/FETCH_PRODUCTS', payload: {storeApiKey: apiKey}
    })


}


export default function* rootSaga() {
    yield all([
        FETCH_TENANT_DETAILS(),
        takeEvery(actions.FETCH_STORES_FOR_TENANT, FETCH_STORES_FOR_TENANT),
        takeEvery(actions.FETCH_STORE_FOR_USER, FETCH_STORE_FOR_USER)

    ])
}
