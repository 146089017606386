import actions from "./actions";

const initialState = {
    organisation: '',
    stores: [],
    tenantApiKey: '',
    storeApiKey: '',
    discounts: []

}
export default function organisationReducer(state = initialState, action){
    const {payload,type} = action
    switch (type) {
        case actions.SET_STATE:
            return {...state,...payload}
        case actions.GET_STATE:
            return {...state}
        default:
            return {...state}

    }
}
